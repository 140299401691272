import { Fragment } from "react";
import "./Sidebar.scss";
import {
    SwipeableDrawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    Icon,
    IconButton,
} from "@mui/material";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";

const Sidebar = ({ open, toggleDrawer }) => {
    const navigate = useNavigate();

    const menuOptions = [
        {
            title: "Inicio",
            icon: "fas fa-home",
            iconColor: "primary",
            onClick: () => navigate("/")
        },
        {
            title: "Conócenos",
            icon: "fas fa-users",
            iconColor: "primary",
            onClick: () => navigate("/conocenos"),
        },
        {
            title: "Servicios",
            icon: "fas fa-cubes",
            iconColor: "primary",
            onClick: () => navigate("/servicios"),
        },
        {
            title: "Blog",
            icon: "fas fa-book-open",
            iconColor: "primary",
            onClick: () => navigate("/blog"),
        },
    ];

    return (
        <Fragment>
            <SwipeableDrawer
                anchor="right"
                open={open}
                onClose={toggleDrawer}
                onOpen={toggleDrawer}
                className="sidebar-container"
            >
                <div className="sidebar-header">
                    <div className="user-container">
                        <Typography className="text-greet">
                            MENU
                        </Typography>
                    </div>
                    <IconButton onClick={toggleDrawer}>
                        <Icon color="primary" className="fas fa-times" />
                    </IconButton>
                </div>

                <div style={{ width: 250 }}>
                    <List className="list-container">
                        {menuOptions.map((option, i) => (
                            <ListItem button onClick={option.onClick} key={i}>
                                <ListItemIcon>
                                    <Icon
                                        color={option.iconColor}
                                        className={clsx(option.icon, "icon")}
                                    />
                                </ListItemIcon>
                                <ListItemText primary={option.title} />
                            </ListItem>
                        ))}
                    </List>
                </div>
            </SwipeableDrawer>
        </Fragment>
    );
};


export default Sidebar;
