import React from "react";
import { Grid, Typography , useMediaQuery} from "@mui/material";
import CardExperiencia from "./cardexperiencia";
import Carrusel from "./Carousel/carrusel";
import imgSeguroVida from "../resources/seguro-vida.png";
import imgMagoLife from "../resources/mangolife.png";
import imgSeguroMascotas from "../resources/seguro-mascotas.png";
import imgPalnGuau from "../resources/planguau.png";
import imgUbicacion from "../resources/ubicacion.png";
import imgSiaviaL from "../resources/siavial.png";

const Cards = [
  <CardExperiencia
    Title="Digitalización de seguros"
    Text= {`Incorporamos nuevas tecnologías para la contratacion de seguros 100% de manera digital` }
    ImageMain={imgSeguroVida}
    ImageSrc={imgMagoLife}
    Uri="https://mangolife.mx"
  />,
  <CardExperiencia
    Title="Productos más simples"
    Text="Impulsados por la innovación hacia nuevas oportunidades en el mercado con productos más simples"
    ImageMain={imgSeguroMascotas}
    ImageSrc={imgPalnGuau}
    Uri="https://planguau.mx"
  />,
  <CardExperiencia
    Title="Servicios más cerca"
    Text="Colaboramos con SIAVIAL para implementación de una plataforma para servicios viales."
    ImageMain={imgUbicacion}
    ImageSrc={imgSiaviaL}
    Uri="https://siavial.com"
  />,
];

export default function Nosotros() {

  const matches = useMediaQuery('(max-width:850px)');

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      align="center"
      id="nosotros"
      className=" paper-style mt-4"
    >
      <Grid item xs={12}>
      <Typography color="primary" variant={matches ? "h5":"h3"} align="center"><b>Nuestra experiencia</b></Typography>
        <Typography variant={matches ? "body2":"subtitle1"}>
          Somos un equipo de desarrolladores que trabajamos de la mano con
          nuestros clientes
        </Typography>
        <Typography variant={matches ? "body2":"subtitle1"}>
          para impulsar sus estrategias y productos digitales.
        </Typography>
      </Grid>

      <Grid item xs={matches ? 1:0} className="mt-4" />
      <Grid item xs={matches ? 10:12} className="mt-4">
        <Carrusel Items={Cards} />
      </Grid>
      <Grid item xs={matches ? 1:0} className="mt-4" />

    </Grid>
  );
}
