import {React , useEffect , useState} from "react";
import { Button, 
  Grid, 
  Typography , 
  useMediaQuery , 
  Icon, 
  IconButton,} from "@mui/material";
  import TextTransition, { presets } from "react-text-transition";
  import { useNavigate } from "react-router-dom";
  
  const TEXTS = [
    "humanas",
    "divertidas",
    "simples",
    "creativas",
    "increíbles",
    "amigables",
    "chidas"
  ];


export default function Inico() {

  const matches = useMediaQuery('(max-width:850px)');
  const [index, setIndex] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const intervalId = setInterval(() =>
      setIndex(index => index + 1),
      800 // 1 segundos
    );
    return () => clearTimeout(intervalId);
  }, []);

  return (
    <Grid id="inicio" className="background-image paper-style">
        <Grid container>
          <Grid item xs={matches ? 6:8} className={matches ? "mt-10":"mt-15"}>
            <Typography  className="text-main-titte">Creamos experiencias digitales</Typography>
          </Grid>
          <Grid item xs={12} >
            <Typography  className="text-main-titte"> más 
            
            <TextTransition
              
              text={` ${TEXTS[index % TEXTS.length]}` }
              springConfig={ presets.gentle }
              style={{ margin: "0 5px" , fontWeight: "800"}}
              inline
              overflow
            />
            </Typography>
            
            
          </Grid>
        </Grid>
        <Button color="secondary" variant="contained" onClick={() => navigate("/conocenos")} className="btn-main mt-8" >Conócenos</Button>
        <Typography className={matches ? "mt-5":"mt-8"}>Siguenos en nuetras redes:</Typography>
        <Grid container>
          <Grid item xs={12} >
            <IconButton color="primary" className="btn-menu" onClick={() => window.open("https://www.facebook.com/ketxalsoluciones", '_blank')}>
                <Icon className="fab fa-facebook-square icon-menu" />
            </IconButton>
            <IconButton color="primary" className="btn-menu" >
                <Icon className="fab fa-linkedin icon-menu" />
            </IconButton>
            <IconButton color="primary" className="btn-menu" onClick={() => window.open("https://twitter.com/ketxalSolutions", '_blank')}>
                <Icon className="fab fa-twitter-square icon-menu" />
            </IconButton>
            <IconButton color="primary" className="btn-menu" href="//api.whatsapp.com/send?phone=524321052935&text=Hola Ketxal , estoy interesado(a) en tus servicios.">
                <Icon className="fab fa-whatsapp-square icon-menu" />
            </IconButton>
          </Grid>
        </Grid>
    </Grid> 
  );
}
