import { React } from "react";
import { Grid, Paper } from "@mui/material";
import Servicios from "../components/servicios";
import Footer from "../components/footer";
import Navbar from "../components/Navbar/Navbar";

export default function Services() {
  return (
    <Paper className="body-container">
      <Navbar />
      <Grid container className="mt-2">
        <Servicios numServices={10} showButton={false} maxWidth={300} />
      </Grid>
      <Footer />
    </Paper>
  );
}
