import React from "react";
import {
  Grid,
  useMediaQuery , 
  Typography, 
  TextField, 
  Button
} from "@mui/material";
import * as Yup from 'yup';
import { useFormik } from 'formik';



const Contacto =()=> {

  const validationSchema = Yup.object({
    nombre: Yup.string().required('Requerido'),
    correo: Yup.string().required('Requerido'),
    asunto: Yup.string().required('Requerido'),
    descripcion: Yup.string().required('Requerido')
  })

  const formik = useFormik({
    initialValues: {
        //inicializar valores
        nombre: '',
        correo: '',
        asunto: '',
        descripcion:''
    },
    onSubmit: values => {

      console.log("values = ", values)

    },
    isInitialValid: false,
    validationSchema,
    validate: values => {
    
    }
})

  const matches = useMediaQuery('(max-width:850px)');
  return (
    <React.Fragment>  
    <form onSubmit={formik.handleSubmit}>
      <Grid  container direction="column" className="paper-style mt-3">
      <Grid>
        <Typography id="contacto"  color="primary" variant={matches ? "h5":"h3"} align="center" ><b>Contáctanos</b></Typography>
        <Typography  align="center"  variant={matches ? "body2":"subtitle1"}>Trabajamos con empresas para crear productos y llevarlos al mercado más rapidamente.</Typography>
        <Typography  align="center" className="mb-2" variant={matches ? "body2":"subtitle1"}>Cuéntanos un poco sobre lo que quieres construir.</Typography>        
      </Grid>
      <Grid  container spacing={matches ? 1:3} align="center">
        <Grid item xs={ matches ? 0:3}></Grid>
        <Grid item xs={ matches ? 12:3}>
          <TextField 
            label="Nombre" 
            variant="outlined"
            name="nombre" 
            size={matches ? "small":"normal"}
            fullWidth
            value={formik.values.nombre}
            error={formik.touched.nombre && formik.errors.nombre ? true : false} 
            onChange={formik.handleChange} onBlur={formik.handleBlur} 
            helperText={formik.touched.nombre && formik.errors.nombre ? formik.errors.nombre : ''}   
            InputProps={{
            className: "textField"
        }}/>
        </Grid>
        <Grid item xs={matches ? 12:3}>
          <TextField 
            label="Correo electrónico" 
            variant="outlined"
            name="correo"
            size={matches ? "small":"normal"} 
            fullWidth
            value={formik.values.correo}
            error={formik.touched.correo && formik.errors.correo ? true : false} 
            onChange={formik.handleChange} onBlur={formik.handleBlur} 
            helperText={formik.touched.correo && formik.errors.correo ? formik.errors.correo : ''}   
            InputProps={{
            className: "textField"
            }}/>
        </Grid>
        <Grid item xs={ matches ? 0:3}></Grid>

        <Grid item xs={ matches ? 0:3}></Grid>
        <Grid item xs={matches ? 12:6}>
        <TextField 
            label="Asunto" 
            variant="outlined"
            name="asunto" 
            size={matches ? "small":"normal"}
            fullWidth
            value={formik.values.asunto}
            error={formik.touched.asunto && formik.errors.asunto ? true : false} 
            onChange={formik.handleChange} onBlur={formik.handleBlur} 
            helperText={formik.touched.asunto && formik.errors.asunto ? formik.errors.asunto : ''}   
            InputProps={{
            className: "textField"
            }}/>
        </Grid>
        <Grid item xs={ matches ? 0:3}></Grid>
        <Grid item xs={ matches ? 0:3}></Grid>
        <Grid item xs={matches ? 12:6}>
          <TextField 
            label="Déjanos tu mensaje" 
            variant="outlined" 
            fullWidth  
            multiline 
            name="descripcion" 
            size={matches ? "small":"normal"}
            rows={5}
            value={formik.values.descripcion}
            error={formik.touched.descripcion && formik.errors.descripcion ? true : false} 
            onChange={formik.handleChange} onBlur={formik.handleBlur} 
            helperText={formik.touched.descripcion && formik.errors.descripcion ? formik.errors.descripcion : ''}   
            InputProps={{
              className: "textField"
          }}/>
        </Grid>
        <Grid item xs={ matches ? 0:3}></Grid>
        <Grid item xs={ matches ? 2:5}></Grid>      
        <Grid xs={matches ? 8:2}>
          <Button color="secondary"  
            fullWidth 
            type="submit"
            variant="contained" 
            className="btn-main mt-1" >Enviar</Button>
        </Grid>
        <Grid item xs={ matches ? 2:5}></Grid>    
      </Grid>
      </Grid>
    </form>
    </React.Fragment> 
  );
}


export default Contacto;