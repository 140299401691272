import React from "react";
import { Grid, Typography , useMediaQuery } from "@mui/material";
import imgAshka from "../resources/aliado-askha.png";
import imgBlockChaninBajio from "../resources/aliado-blockchain.png";
import imgCalzadaCode from "../resources/aliado-calzada-code.png";
import imgDistrito106 from "../resources/aliado-distrito106.png";
import imgRedTeam from "../resources/aliado-redteam.png";
import Fade from 'react-reveal/Fade';

const ArrayAliados = [
  imgAshka,
  imgBlockChaninBajio,
  imgCalzadaCode,
  imgDistrito106,
  imgRedTeam,
];

export default function Aliados() {
  const matches = useMediaQuery('(max-width:850px)');

  return (
    <Grid container direction="column" className="paper-style">
      <Grid id="aliados">
        <Typography color="primary" variant={matches ? "h5":"h3"} align="center" className={matches ? "":"mt-5"}><b>Aliados estratégicos</b></Typography>
        <Typography align="center"  variant={matches ? "body2":"subtitle1"}>
          Cuando hay trabajo en equipo y colaboración, cosas maravillosas pueden
          lograrse.
        </Typography>
        <Typography align="center" variant={matches ? "body2":"subtitle1"}>
          Estos son algunos de nuestros aliados.
        </Typography>
      </Grid>

      <Fade left>
      <Grid item xs={12} className="mt-3" >
        <Grid container justifyContent="center" spacing={4}>
          {ArrayAliados.map((aliado, i) => (
            <Grid key={`aliado_${i}`} item >
              <img 
                className="aliado-img" 
                src={aliado} 
                alt="..." />
            </Grid>
          ))}
        </Grid>
      </Grid>
      </Fade>

    </Grid>
  );
}
