import * as React from 'react';
import {
  Card , 
  CardContent ,
  CardMedia , 
  Typography ,
  CardActionArea,
  useMediaQuery
} 
from '@mui/material';

export default function CardServicios({urlImage, title, description, items }) {
  const matches = useMediaQuery('(max-width:850px)');
  return (
    <Card sx={{ 
      maxWidth: matches ? 400: 300 , 
      margin: matches ? 1:2, 
      borderRadius: 5}}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="120"
          image={urlImage}
          alt=""
        />
        <CardContent>
          <Typography gutterBottom variant="h6" align='center' className="card-title">
            <b>{title}</b>
          </Typography>
          <Typography variant="body2" className=" card-description mb-1">
            {description}
          </Typography>
          {
            items.map((step)=>(
              <Typography variant="body2" color="primary" className="card-description"> 
              <li>{step}</li>
              </Typography>
            ))
          }
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
