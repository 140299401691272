import { Fragment, useState } from "react";
import "./Navbar.scss";
import {
  AppBar,
  Toolbar,
  Icon,
  IconButton,
  useMediaQuery,
  Grid,
} from "@mui/material";
import { NavHashLink as Link } from "react-router-hash-link";

import Sidebar from "../Sidebar/Sidebar";

const Navbar = () => {
  const matches = useMediaQuery("(max-width:768px)");
  const [drawer, setDrawer] = useState(false);

  const toggleDrawer = () => {
    setDrawer(!drawer);
  };

  return (
    <Fragment>
      <Sidebar open={drawer} toggleDrawer={() => toggleDrawer()} />
      <AppBar position="fixed" className="nav-bar" elevation={0}>
        <Toolbar className="toolbar">
          <img
            src="https://assets-ketxal.s3.us-east-1.amazonaws.com/logo-transparente.png"
            alt=""
            className="logo"
          />

          {matches ? (
            <div className="actions-container">
              <IconButton
                color="secondary"
                className="btn-menu"
                onClick={() => toggleDrawer()}
              >
                <Icon className="fas fa-bars icon-menu" />
              </IconButton>
            </div>
          ) : (
            <Grid className="actions-container">
              <Grid>
                <Link className="item-menu" to="/">Inicio</Link>
              </Grid>
              <Grid>
                <Link className="item-menu" to="/conocenos">Conócenos</Link>
              </Grid>
              <Grid>
                <Link className="item-menu" to="/servicios">
                  Servicios
                </Link>
              </Grid>
              <Grid>
                <Link className="item-menu" to = "/blog">
                  Blog
                </Link>
              </Grid>
              <Grid>
                <Link className="item-menu" to="/contactanos">
                  Contacto
                </Link>
              </Grid>
            </Grid>
          )}
        </Toolbar>
      </AppBar>
    </Fragment>
  );
};

export default Navbar;
