import { React } from "react";
import { Grid, Paper } from "@mui/material";

import Footer from "../components/footer";
import Navbar from "../components/Navbar/Navbar";
import Contacto from "../components/contacto";

export default function Contactanos() {
  return (
    <Paper className="body-container">
      <Navbar />
      <Grid container className="mt-2">
        <Contacto />
      </Grid>
      <div
        style={{
          position: "fixed",
          width: "100%",
          bottom: 0,
        }}
      >
        <Footer />
      </div>
    </Paper>
  );
}
