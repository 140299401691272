import {React, useState} from "react";
import CardServicios from "./cardservicios";
import { Button, 
  Grid, 
  Typography , 
  useMediaQuery , 
} from "@mui/material";
import { servicios } from '../resources/servicios-info.json';
import Fade from 'react-reveal/Fade';

export default function Servicios() {

  const matches = useMediaQuery('(max-width:850px)');
  const [showMore , setShowMore ]= useState(true)
  const [num , setNum ]= useState(5)

  const handleShowMore=()=>{
    setNum(showMore ? 10:5);
    setShowMore(!showMore)
  }

  return (
    <Grid  container
      direction="column"
      id="servicios"
    >
      <Grid className="paper-style mt-2">
      <Typography color="primary" variant={matches ? "h5":"h3"} align="center" ><b>Servicios</b></Typography>
      <Typography  align="center" variant={matches ? "body2":"subtitle1"} >Impulsados por la innovación y las mejores practicas de desarrollo de software,</Typography>
      <Typography align="center" variant={matches ? "body2":"subtitle1"}>estos son algunos de nuestros servicios:</Typography>        
      </Grid>
      <Grid  container
        direction="row"
        className="paper-style"
      >
        {
          servicios.map((servicio)=>(
            servicio.id < num ? ( 
              <Fade left>
                <CardServicios
                  key= {`card-serv-${servicio.id}`}
                  urlImage={servicio.urlImage}
                  title={servicio.tittle}
                  description={servicio.description}
                  items={servicio.items}
                  />
              </Fade>):null
          ))
        }
      </Grid>
          <Grid  container
            direction="row"
            justifyContent="center"
          >
            <Button 
            color="secondary" 
            variant="contained" 
            onClick={()=>{ handleShowMore()}}
            className="btn-main" >
              {showMore ? "Mostrar más ":"Mostrar menos"}
              </Button>
          </Grid>      
      </Grid>
  );
}
