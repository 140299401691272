import { React } from "react";
import { Paper } from "@mui/material";

import Inicio from "../components/inicio";
import Servicios from "../components/servicios";
import Nosotros from "../components/nosotros";
import Contacto from "../components/contacto";
import Footer from "../components/footer";
import Aliados from "../components/aliados";
import Cultura from "../components/cultura";
import Navbar from "../components/Navbar/Navbar";

export default function Main() {
  return (
    <Paper className="body-container">
      <Navbar />
      <Inicio />
      <Servicios />
      <Nosotros />
      <Cultura />
      <Aliados />
      <Contacto /> 
      <Footer />
    </Paper>
  );
}
