import {Grid, Paper, Typography, useMediaQuery } from "@mui/material";
import React from "react";

export default function CardExperiencia({
  Title,
  Text,
  ImageMain,
  ImageSrc,
  Uri,
}) {

  const matches = useMediaQuery('(max-width:850px)');

  return (
    <Paper sx={{ flexGrow: 1, borderRadius: "20px", maxWidth:"500px" }}>
      <Grid container >
        <Grid
          item xs={4}
          align="center"
          style={{
            backgroundColor: "#F69E28",
            borderTopLeftRadius: "20px",
            borderBottomLeftRadius: "20px",
          }}
        >
            <img
              alt="complex"
              src={ImageMain}
              align="center"
              style={{
                maxHeight: matches ? "240px":"360px",
                maxWidth: matches ? "100px":"170px",
              }}
            />
        </Grid>
        <Grid item xs={8} sm container >
          <Grid item xs container direction="column"  >
            <Grid item xs className="p-1">
              <Typography  variant="h6" align='center' className="card-title"><b>{Title}</b></Typography>
              <Typography  variant="body2" className="card-description mb-1">{Text}</Typography>
            </Grid>
            <Grid item>
            <img
                alt={Title}
                src={ImageSrc}
                className="exp-card-secondary-img"
                onClick={() => window.open(Uri, '_blank')}
              />
              <Typography sx={{ cursor: "pointer" }} variant="body2" className="card-description mb-1" onClick={() => window.open(Uri, '_blank')}>
                {Uri}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
    </Paper>
  );
}
