import React from "react";
import { 
  Grid, 
  Typography,
  useMediaQuery,
  IconButton,
  Icon
} from "@mui/material";
import { NavHashLink as Link } from "react-router-hash-link";

export default function Footer() {

  const matches = useMediaQuery('(max-width:850px)');

  return (
    <Grid>
    <Grid container className="footer-bg1 mt-4 p-1">
      <Grid item xs={matches ? 7:3} className="mt-1">
          <img
              src="https://assets-ketxal.s3.us-east-1.amazonaws.com/logo-transparente.png"
              alt=""
              className="img-logo"
          />
      </Grid>
      <Grid item xs={matches ? 5:3}  className="mt-1">
        <Typography  className="footer-title">Menu</Typography>
        <Grid container direction="column" >
          <Link className="footer-text" to="/#inicio">Inicio</Link>
          <Link className="footer-text" to="/servicios">Servicios</Link>
          <Link className="footer-text" to="/blog">Blog</Link>
          <Link className="footer-text" to="/conocenos">Conónenos</Link>
          
          
          
        </Grid>
      </Grid>
      <Grid item xs={matches ? 7:3}  className="mt-1">
        <Typography  className="footer-title">Contacto</Typography>
        <Grid container direction="column" >
          <Link className="footer-text" to="/#contacto">
            <Icon className="far fa-envelope icon-contact" />
            contacto@ketxal.com
          </Link>
          <a className="footer-text" href="tel:4321052935">
            <Icon className="fas fa-phone icon-contact"/>
              +52 (432) 105 2935
            </a>
          <Typography className="footer-text" >
            <Icon className="fas fa-map-marker-alt icon-contact" />
            León , Guanajuato
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={matches ? 5:3}  className="mt-1">
        <Typography className="footer-title">Síguenos</Typography>
        <Grid item xs={12} >
            <IconButton color="primary"  onClick={() => window.open("https://www.facebook.com/ketxalsoluciones", '_blank')}>
                <Icon className="fab fa-facebook-square icon-social" />
            </IconButton>
            <IconButton color="primary"  >
                <Icon className="fab fa-linkedin icon-social" />
            </IconButton>
            <IconButton color="primary">
                <Icon className="fab fa-twitter-square icon-social" onClick={() => window.open("https://twitter.com/ketxalSolutions", '_blank')}/>
            </IconButton>
            <IconButton color="primary" href="//api.whatsapp.com/send?phone=524321052935&text=Hola Ketxal , estoy interesado(a) en tus servicios.">
                <Icon className="fab fa-whatsapp-square icon-social" />
            </IconButton>
          </Grid>
      </Grid>
    </Grid>
  <Grid item xs={12} className="footer-bg2  p-1 ">
    <Typography className="footer-subtext" >(C) Copyright Ketxal 2021</Typography>
  </Grid>
  </Grid>
  );
}
