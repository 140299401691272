import { Grid, Paper, Typography, useMediaQuery } from "@mui/material";
import {React , useState , useEffect} from "react";
import Footer from "../components/footer";
import Navbar from "../components/Navbar/Navbar";
import TextTransition, { presets } from "react-text-transition";
import Lottie from "react-lottie";
import Computer from "../resources/lotties/computer.json";

const TEXTS = [
  "humanas",
  "divertidas",
  "simples",
  "creativas",
  "increíbles",
  "amigables",
  "chidas"
];


export default function Conocenos() {
  const matches = useMediaQuery("(max-width:850px)");
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() =>
      setIndex(index => index + 1),
      800 // 1 segundos
    );
    return () => clearTimeout(intervalId);
  }, []);

  return (
    <Paper className="body-container">
      <Navbar />
      <Grid container direction="column" className="mt-2">
        <Grid container direction="row" className="paper-style mt-2">
          <Grid item xs={matches ? 0:1}/>
          <Grid item xs={matches ? 6:5} >
            <Typography className="blog-title">Creamos experiencias digitales </Typography>
            <Typography  className="blog-title"> más 
            <TextTransition
              text={` ${TEXTS[index % TEXTS.length]}` }
              springConfig={ presets.gentle }
              style={{ margin: "0 10px" , fontWeight: "800" , color: "#2A7C81"}}
              inline
              overflow
            />
            </Typography>
            <Typography className="blog-subtitle mt-1"> <b>Ketxal Soluciones Tecnológicas</b> es un equipo joven de desarrolladores de software con más de 10 años de experiencia en la creación de soluciones tecnológicas. </Typography>
          </Grid>
          <Grid item xs={matches ? 6:5}  align="center">
            <img
                src="https://assets-ketxal.s3.us-east-1.amazonaws.com/ketxal-logo-blog.png"
                alt=""
                className="blog-image"
            />
          </Grid>
          <Grid item xs={matches ? 0:1}/>
        </Grid>
        <Grid container direction="row" className="mt-2" spacing={2}>
          <Grid item xs={matches ? 0:1}/>
          <Grid item xs={matches ? 5:5}  align="center">
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: Computer,
            }}
            width={matches ? "110%": "100%"}
          />
          </Grid>
          <Grid item xs={matches ? 6:5} >
          <Typography className="blog-label">Nuestra misión</Typography>
            <Typography className="blog-title">Crear soluciones simples a problemas complejos de nuestros clientes.</Typography>
            <Typography className="blog-subtitle mt-1"> Analizamos las necesidades de nuestros clientes para apoyar en el <b>crecemiento de su negocio.</b>  </Typography>
          </Grid>
          
          <Grid itemxs={matches ? 0:1}/>
        </Grid>
      </Grid>
        <Footer />
    </Paper>
  );
}
