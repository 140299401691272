import { Grid, Paper, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import Footer from "../components/footer";
import Navbar from "../components/Navbar/Navbar";
import Lottie from "react-lottie";
import UnderConstructionAnimation from "../resources/lotties/lottie-under-construction.json";

export default function Blog() {
  const matches = useMediaQuery("(max-width:850px)");
  return (
    <Paper className="body-container">
      <Navbar />
      <Grid container direction="column" className="mt-2">
        <Grid className="paper-style ">
          <Typography
            color="primary"
            rec
            variant={matches ? "h5" : "h3"}
            align="center"
            className="mt-2"
          >
            <b>Nuestro blog</b>
          </Typography>
          <Typography
            color="primary"
            rec
            variant={matches ? "subtitle2" : "h6"}
            align="center"
          >
            Página en contrucción
          </Typography>
        </Grid>
        <Grid container direction="column" align="center">
          {/* <img alt="complex" src={ImageKetxal}  
            style={{
              width:"100%",
              height:"auto"
            }}
          /> */}

          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: UnderConstructionAnimation,
            }}
            width={matches ? "70%": "36%"}
          />
        </Grid>
      </Grid>
      <div style={{ position: "fixed", width: "100%", bottom: 0 }}>
        <Footer />
      </div>
    </Paper>
  );
}
