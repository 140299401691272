import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./styles/main.scss";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Main from "./pages/main";
import Services from "./pages/services";
import Blog from "./pages/blog";
import Conocenos from "./pages/conocenos";
import Contactanos from "./pages/contactanos";

function App() {
  const theme = createTheme({
    palette: {
      type: "light",
      primary: {
        main: "#2A7C81",
      },
      secondary: {
        main: "#F69E28",
      },
    },
    typography: {
      fontFamily: "Quicksand",
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/servicios" element={<Services />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/conocenos" element={<Conocenos />} />
          <Route path="/contactanos" element={<Contactanos />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;

