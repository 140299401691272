import React from "react";
import {
  Grid, 
  Typography ,
  useMediaQuery
} from "@mui/material";
import imgReloj from "../resources/time.png";
import imgScrum from "../resources/scrum.png";
import Fade from 'react-reveal/Fade';

export default function Cultura() {

  const matches = useMediaQuery('(max-width:850px)');
  return (
    <Grid  container
    direction="row"
    id="nosotros"
    className="background-image-scrum paper-style mt-3">
      <Grid container className="mb-3">
        <Grid item xs={8}>
            <Typography color="primary" variant={matches ? "h5":"h3"} align="left"><b>Una cultura ágil...</b></Typography>
        </Grid>
        <Grid item xs={1} className="mt-1"/>
        <Grid item xs={matches ? 12: 5} className="mt-1" align="left">
            <Grid container>
              <Fade left>
                <Grid item xs={matches ? 9:12} className="mt-1">
                    <Typography variant={matches ? "body2":"subtitle1"}>Un trabajo colaborativo basado en <b>SCRUM</b></Typography>
                </Grid>
                <Grid item xs={12} >
                  <img
                      alt="complex"
                      src={imgReloj}
                      align="center"
                      style={{
                        maxHeight: matches ? "240px":"260px",
                        maxWidth: matches ? "100px":"260px",
                      }}
                  />
                </Grid>
                <Grid item xs={matches ? 9:12}>
                    <Typography variant={matches ? "body2":"subtitle1"}>Somos un equipo <b>orquestado</b> por metodologías 
                      ágiles que mantienen alineadas las necesidades 
                      de nuestros clientes.
                    </Typography>
                </Grid>
                <Grid item xs={matches ? 6:12} className="mt-2">
                    <Typography variant={matches ? "body2":"subtitle1"}>Nuestros expertos certificados en <b>SCRUM Master</b> forman 
                      parte importante para mejorar, acelerar y cumplir las metas.
                    </Typography>
                </Grid>
                <Grid item xs={12} className="mt-1">
                  <img
                      alt="complex"
                      src={imgScrum}
                      align="center"
                      style={{
                        maxHeight: matches ? "240px":"260px",
                        maxWidth: matches ? "100px":"260px",
                      }}
                  />
                </Grid>
                </Fade>
            </Grid>
        </Grid>
        <Grid item xs={6} className="mt-1" align="right"/>
      </Grid>
    </Grid>
  );
}
